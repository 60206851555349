<template>
  <b-modal
    centered
    size="md"
    no-close-on-backdrop
    hide-header-close
    v-model="isModalOpen"
  >
    <template #modal-title>Delete Confirmation</template>
    <template #default>
      <div class="modal-custom-content">
        <p>Are you sure you want to delete the {{ deleteInfo }} ?</p>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-btn text-right">
        <button class="btn btn-secondary" @click.prevent="$emit('no')">
          No
        </button>
        <button class="btn btn-primary" @click.prevent="$emit('yes')">
          Yes, delete it
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalDelete',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    deleteData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get() {
        return this.isOpen
      },
      set() {
        this.$emit('update:isOpen', false)
      }
    },
    deleteInfo() {
      if (!this.deleteData.deleteType) {
        return ''
      }

      if (this.deleteData.deleteType === 'user') {
        const { firstName, lastName } = this.deleteData
        return `user ${firstName} ${lastName}`
      }

      if (this.deleteData.deleteType === 'subscription') {
        const { companyName } = this.deleteData
        return `subscription ${companyName}`
      }

      return ''
    }
  },
  methods: {
    transformType(s) {
      if (!s) return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }
}
</script>
