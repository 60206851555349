<template>
  <main class="subscriptionView">
    <b-breadcrumb :items="breadcrumb" />
    <div v-if="viewShow" class="container" :class="{ 'mx-auto': !sidebarShow }">
      <div class="section-userInfo">
        <div class="section-userInfo-company">
          Company :
          <span class="font-weight-bold">{{ subscription.companyName }}</span>
        </div>
        <div class="section-userInfo-active">
          Max Active :
          <span class="font-weight-bold">{{
            subscription.maxNumOfActiveTasks
          }}</span>
        </div>
        <div class="section-userInfo-active">
          Valid From :
          <span class="font-weight-bold">{{
            dateFormat(subscription.validFrom)
          }}</span>
        </div>
        <div class="section-userInfo-active" v-if="subscription.validUntil">
          Valid Until :
          <span class="font-weight-bold">{{
            dateFormat(subscription.validUntil)
          }}</span>
        </div>
      </div>
      <section class="section">
        <div class="section-title subscriptionView-title">User</div>
        <div class="section-table subscriptionView-table">
          <div class="section-table-status">
            <div class="row">
              <div class="col-auto">
                <router-link
                  class="btn btn-primary"
                  :to="`/platform/user/new?subscriptionId=${subscription.id}`"
                  >New User</router-link
                >
              </div>
            </div>
          </div>
          <div class="section-table-content" v-if="usersData">
            <CDataTable
              class="subscriptionUser-dataTable"
              :items="usersData"
              :fields="fields"
              :table-filter="{
                label: 'Search:',
                placeholder: ''
              }"
              :items-per-page="10"
              :pagination="{ align: 'center' }"
              :itemsPerPageSelect="{ values: [10, 20, 50, 100] }"
              hover
              :sorter="{ resetable: true }"
            >
              <template #sorting-icon="{state}">
                <TableSortingIcon :state="state"></TableSortingIcon>
              </template>
              <template #show_details="{item}">
                <td>
                  <div class="dataTable-btns">
                    <router-link
                      class="btn btn-outline-primary btn-sm dataTable-btn"
                      :to="`/platform/user/${item.id}/edit`"
                    >
                      Edit
                    </router-link>
                    <button
                      class="btn btn-outline-danger btn-sm dataTable-btn"
                      @click.prevent="deleteModalOpen(item)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </section>
    </div>
    <ModalDelete
      :isOpen="deleteModalShow"
      :deleteData="deleteData"
      @yes="userDelete"
      @no="deleteModalCancel"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CDataTable } from '@coreui/vue/src'
import TableSortingIcon from '../components/TableSortingIcon.vue'
import ModalDelete from '../components/ModalDelete.vue'

export default {
  name: 'SubscriptionView',
  components: { CDataTable, TableSortingIcon, ModalDelete },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Subscription',
          to: '/platform/subscription'
        },
        {
          text: 'view',
          active: true
        }
      ],
      viewShow: false,
      fields: [
        { key: 'username', label: 'Name', _style: 'min-width:150px' },
        { key: 'email', label: 'Email', _style: 'min-width:200px' },
        { key: 'role', label: 'Role', _style: 'min-width:150px;' },
        {
          key: 'show_details',
          _style: 'min-width:100px;',
          label: '',
          sorter: false,
          filter: false
        }
      ],
      deleteModalShow: false,
      deleteData: {}
    }
  },
  async mounted() {
    let id = this.$route.params.id
    let status = await this['subscription/pageViewCreated']({ id })
    if (status === 'success') {
      this.viewShow = true
    } else {
      console.log('subscriptionView created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      subscription: state => state.subscription.view,
      users: state => state.subscription.userList
    }),
    usersData() {
      if (!this.users) return []
      return this.users.map(user => {
        const { id, firstName, lastName, email, role } = user
        return {
          id,
          username: `${firstName} ${lastName}`,
          email,
          role
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'subscription/pageViewCreated',
      'subscription/pageViewUserDelete'
    ]),
    dateFormat(date) {
      if (date == null) return null
      return new Date(date).toLocaleDateString()
    },
    deleteModalOpen(user) {
      this.deleteModalShow = true
      this.deleteData = {
        ...user,
        deleteType: 'user'
      }
    },
    deleteModalCancel() {
      this.deleteModalShow = false
      this.deleteData = {}
    },
    async userDelete() {
      this.deleteModalShow = false
      const id = this.deleteData.id
      let status = await this['subscription/pageViewUserDelete']({ id })
      if (status === 'success') {
        this.deleteData = {}
      } else {
        this.deleteData = {}
        console.log('subscription user delete: ' + status)
        // this.$router.push('/pages/page403')
      }
    }
  }
}
</script>
