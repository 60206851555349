var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"subscriptionView"},[_c('b-breadcrumb',{attrs:{"items":_vm.breadcrumb}}),(_vm.viewShow)?_c('div',{staticClass:"container",class:{ 'mx-auto': !_vm.sidebarShow }},[_c('div',{staticClass:"section-userInfo"},[_c('div',{staticClass:"section-userInfo-company"},[_vm._v(" Company : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.subscription.companyName))])]),_c('div',{staticClass:"section-userInfo-active"},[_vm._v(" Max Active : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.subscription.maxNumOfActiveTasks))])]),_c('div',{staticClass:"section-userInfo-active"},[_vm._v(" Valid From : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.dateFormat(_vm.subscription.validFrom)))])]),(_vm.subscription.validUntil)?_c('div',{staticClass:"section-userInfo-active"},[_vm._v(" Valid Until : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.dateFormat(_vm.subscription.validUntil)))])]):_vm._e()]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"section-title subscriptionView-title"},[_vm._v("User")]),_c('div',{staticClass:"section-table subscriptionView-table"},[_c('div',{staticClass:"section-table-status"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":("/platform/user/new?subscriptionId=" + (_vm.subscription.id))}},[_vm._v("New User")])],1)])]),(_vm.usersData)?_c('div',{staticClass:"section-table-content"},[_c('CDataTable',{staticClass:"subscriptionUser-dataTable",attrs:{"items":_vm.usersData,"fields":_vm.fields,"table-filter":{
              label: 'Search:',
              placeholder: ''
            },"items-per-page":10,"pagination":{ align: 'center' },"itemsPerPageSelect":{ values: [10, 20, 50, 100] },"hover":"","sorter":{ resetable: true }},scopedSlots:_vm._u([{key:"sorting-icon",fn:function(ref){
            var state = ref.state;
return [_c('TableSortingIcon',{attrs:{"state":state}})]}},{key:"show_details",fn:function(ref){
            var item = ref.item;
return [_c('td',[_c('div',{staticClass:"dataTable-btns"},[_c('router-link',{staticClass:"btn btn-outline-primary btn-sm dataTable-btn",attrs:{"to":("/platform/user/" + (item.id) + "/edit")}},[_vm._v(" Edit ")]),_c('button',{staticClass:"btn btn-outline-danger btn-sm dataTable-btn",on:{"click":function($event){$event.preventDefault();return _vm.deleteModalOpen(item)}}},[_vm._v(" Delete ")])],1)])]}}],null,false,57216351)})],1):_vm._e()])])]):_vm._e(),_c('ModalDelete',{attrs:{"isOpen":_vm.deleteModalShow,"deleteData":_vm.deleteData},on:{"yes":_vm.userDelete,"no":_vm.deleteModalCancel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }